<template>
  <div class="row">
    <div class="col-md-12">
       <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)">
          <i class="fa fa-chevron-left"></i>
        </span>
      <h3>{{ $t('invoice.accepted_invoice') }}</h3>
    </div>
    <div class="col-md-12 h-50vh">
      <dashboard-box>
        <template v-slot:preview>
          <div class="px-5 py-3">
            <div class="row items-start">
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest">
                <div class="col-12 items-start">
              <date-picker-input
                      :title="$t('invoice.date')"
                      :model="today"
                      :required="false"
                      :disabled="true"
                  ></date-picker-input>
                </div>
                <div class="col-12">
                  <custom-multi-select
                      :title="$t('invoice.order_number')"
                      :model.sync="form.waybillable_id"
                      name="invoice_order_number"
                      :item-per-row="1"
                      :required="true"
                      :disabled="true"
                      :options="options.waybillableOptions"
                      :max="1"
                      :not-list="true"
                  ></custom-multi-select>
                </div>
                <div class="col-12">
                  <custom-multi-select
                      :title="$t('invoice.company_name')"
                      :model.sync="form.supplier_company_id"
                      name="invoice_company_name"
                      :item-per-row="1"
                      :required="true"
                      :disabled="true"
                      :options="options.companyOptions"
                      :max="1"
                      :not-list="true"
                  ></custom-multi-select>
                </div>
                  <div class="col-12">
                    <number-input
                        :title="$t('invoice.document_no')"
                        :model.sync="form.document_no"
                        :disabled="true"
                        :required="true"
                        :placeholder="$t('invoice.please_enter_invoice_document_no')"
                    ></number-input>

                </div>
                <div class="col-12 d-lg-none d-md-flex mb-3 ">
                  <div class="w-200px">
                    <span class="text-lg">
                      {{ $t('invoice.add_withholding') }}
                    </span>
                  </div>
                  <div class="flex-grow-1">
                    <b-form-checkbox
                        class="checkbox"
                        id="invoice_withholding"
                        v-model="form.is_withholding"
                        :disabled="true"
                        name="invoice_withholding"
                        value="1"
                        unchecked-value="0"
                    ></b-form-checkbox>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest">
                <div class="col-12">
                  <number-input
                      :title="$t('invoice.invoice_number')"
                      :model.sync="form.invoice_no"
                      :disabled="true"
                      :required="true"
                      :placeholder="$t('invoice.please_enter_invoice_number')"
                  ></number-input>
                </div>
                <div class="col-12">
                  <date-picker-input
                      :title="$t('invoice.invoice_date')"
                      :required="true"
                      :disabled="true"
                      :model.sync="form.invoice_date"
                  ></date-picker-input>
                </div>
                <div class="col-12 d-lg-none d-md-block">
                  <custom-multi-select
                      :title="$t('invoice.vat')"
                      :options="options.vatOptions"
                      :model.sync="form.vat_id"
                      :disabled="true"
                      name="vat_rate_id"
                      :not-list="true"
                      :max="1"
                  ></custom-multi-select>
                </div>
                <div class="col-12 d-flex justify-content-start">
                  <custom-multi-select
                      :title="$t('invoice.invoice_type')"
                      :required="true"
                      :options="options.invoiceTypeOptions"
                      :model.sync="form.invoice_type_id"
                      :disabled="true"
                      :max="1"
                      :not-list="true"
                      :is-inline="false"
                      class="mr-3"
                      name="invoice_type"
                  ></custom-multi-select>
                  <custom-multi-select
                      class="mr-3"
                      :title="`Tipi`"
                      :required="true"
                      :options="options.invoiceKindOptions"
                      :model.sync="form.invoice_kind_id"
                      :max="1"
                      :not-list="true"
                      :is-inline="false"
                      :disabled="true"
                      name="invoice_kind"
                  ></custom-multi-select>
                  <custom-multi-select
                      :title="$t('order.expense')"
                      :required="true"
                      :options="options.expenseTypeOptions"
                      :model.sync="form.invoice_category_id"
                      :max="1"
                      :not-list="true"
                      :is-inline="false"
                      name="expense_type"
                      :disabled="true"
                  ></custom-multi-select>
                </div>
              </div>
              <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 row mx-0 font-weight-boldest">
                <div class="col-12 mb-3 d-lg-flex d-md-none">
                  <div class="w-200px">
                    <span class="text-lg">
                      {{ $t('invoice.add_withholding') }}
                    </span>
                  </div>
                  <div class="flex-grow-1">
                    <b-form-checkbox
                        class="checkbox"
                        id="invoice_withholding"
                        v-model="form.is_withholding"
                        name="invoice_withholding"
                        value="1"
                        unchecked-value="0"
                        :disabled="true"
                    ></b-form-checkbox>
                  </div>
                </div>
                <div class="col-12 d-lg-block d-md-none">
                  <custom-multi-select
                      :title="$t('invoice.currency')"
                      :options="options.currencyOptions"
                      :model.sync="form.currency_unit_id"
                      name="vat_rate_id"
                      :not-list="true"
                      :max="1"
                      :disabled="true"
                  ></custom-multi-select>
                </div>
                <div class="col-12 d-lg-block d-md-none" v-if="form.is_withholding == 1">
                  <custom-multi-select
                      :title="$t('invoice.vatDeduct')"
                      :options="options.vatDeducts"
                      :model.sync="form.vat_deduct_id"
                      name="vat_deduct_id"
                      :not-list="true"
                      :max="1"
                      :disabled="true"
                  ></custom-multi-select>
                </div>
                <div class="col-12 d-lg-block d-md-none" v-if="form.is_withholding == 1">
                  <text-input
                      class="font-weight-bolder"
                      :title="$t('invoice.description')"
                      :placeholder="''"
                      name="vat_deduct_description"
                      :model.sync="form.vat_deduct_description"
                      :helper-text="$t('please_enter_manually')"
                      :is-inline="true"
                      input-width="125px"
                      :disabled="true"
                  ></text-input>
                </div>

                <div class="col-12 d-lg-block d-md-none">
                  <custom-multi-select
                      :title="$t('invoice.vat')"
                      :options="options.vatOptions"
                      :model.sync="form.vat_id"
                      name="vat_rate_id"
                      :not-list="true"
                      :max="1"
                      :disabled="true"
                  ></custom-multi-select>
                </div>
                <div class="col-12 d-lg-block d-md-none"  v-if="showVatExceptionField">
                  <custom-multi-select
                      :title="$t('invoice.vatException')"
                      :options="options.vatExceptions"
                      :model.sync="form.vat_exception_id"
                      name="vat_exception_id"
                      :not-list="true"
                      :max="1"
                      :disabled="true"
                  ></custom-multi-select>
                </div>
                <div class="col-12 d-lg-block d-md-none" v-if="showVatExceptionField">
                  <text-input
                      class="font-weight-bolder"
                      :title="$t('invoice.description')"
                      :placeholder="''"
                      name="vat_exception_description"
                      :model.sync="form.vat_exception_description"
                      :helper-text="$t('please_enter_manually')"
                      :is-inline="true"
                      input-width="125px"
                      :disabled="true"
                  ></text-input>
                </div>

                <div class="col-12 py-2" v-show="form.image !== null">
                  <img :src="form.image ? form.image : ''" id="invoice_image" class="w-75px h-75px object-cover primary-border-1px border-15px">
                </div>
              </div>
            </div>
            <div class="w-100 table-responsive border-15px navy-blue-border-1px mx-3 mt-5 mb-1">
              <table class="table w-100 border-15px mb-0">
                <thead class="w-100">
                <tr>
                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px" v-if="+route_type === 2 || +route_type === 3">
                    <div class="text-right text-lg pr-10">
                      {{ $t('invoice.waybill_date') }}
                    </div>
                  </th>
                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                    <div class="text-right text-lg pr-10">
                      {{ $t('invoice.code') }}
                    </div>
                  </th>
                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                    <div class="d-flex justify-content-center items-center text-lg">
                      {{ $t('invoice.code_name') }}
                      <info-tooltip
                          :helper-text="$t('invoice.please_enter_manually')"
                      ></info-tooltip>
                    </div>
                  </th>
                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                    <div class="d-flex justify-content-center items-center text-lg">
                      {{ $t('invoice.amount') }}
                      <info-tooltip
                          :helper-text="$t('invoice.please_enter_manually')"
                      ></info-tooltip>
                    </div>
                  </th>
                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                    <div class="d-flex justify-content-center items-center text-lg">
                      {{ $t('invoice.unit') }}
                      <info-tooltip
                          :helper-text="$t('invoice.please_enter_manually')"
                      ></info-tooltip>
                    </div>
                  </th>
                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                    <div class="d-flex justify-content-center items-center text-lg">
                      {{ $t('invoice.unit_price') }}
                      <info-tooltip
                          :helper-text="$t('invoice.please_enter_manually')"
                      ></info-tooltip>
                    </div>
                  </th>
                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                    <div class="d-flex justify-content-center items-center text-lg">
                      {{ $t('invoice.vat_amount') }}
                      <info-tooltip
                          :helper-text="$t('invoice.please_enter_manually')"
                      ></info-tooltip>
                    </div>
                  </th>
                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                    <div class="d-flex justify-content-center items-center text-lg">
                      {{ $t('invoice.index_amount') }}
                      <info-tooltip
                          :helper-text="$t('invoice.please_enter_manually')"
                      ></info-tooltip>
                    </div>
                  </th>
                  <th class="navy-blue-border-right-1px navy-blue-border-bottom-1px">
                    <div class="d-flex justify-content-center items-center text-lg">
                      {{ $t('invoice.amount_total') }}
                      <info-tooltip
                          :helper-text="$t('invoice.please_enter_manually')"
                      ></info-tooltip>
                    </div>
                  </th>
                  <th class="navy-blue-border-bottom-1px">
                    <div class="d-flex justify-content-center items-center text-lg">
                      {{ $t('order.description') }}
                    </div>
                  </th>
                </tr>
                </thead>
                <tbody class="navy-blue-bottom-1px">
                <tr  >
                  <td class="navy-blue-border-right-1px" v-if="+route_type === 2 || +route_type === 3">
                    {{form.waybill_date | momentDateFormat}}
                  </td>
                  <td class="navy-blue-border-right-1px">
                    <input class="form-control d-inline-block mb-5" type="text" v-model="form.code" disabled>
                  </td>
                  <td class="navy-blue-border-right-1px items-start">
                    <input class="form-control d-inline-block mb-5" type="text" v-model="form.code_name" disabled
                    >
                  </td>
                  <td class="navy-blue-border-right-1px items-start">
                    <input class="form-control d-inline-block mb-5" type="number" v-model="form.amount" disabled
                    >
                  </td>
                  <td class="navy-blue-border-right-1px items-start">
                    <custom-multi-select
                        :options="options.unitOptions"
                        :model.sync="form.select_unit_id"
                        :is-inline="false"
                        :title="null"
                        :required="false"
                        name=""
                        :input-width="`65px`"
                        :item-per-row="1"
                        :not-list="true"
                        :max="1"
                        :disabled="true"
                    ></custom-multi-select>
                  </td>
                  <td class="navy-blue-border-right-1px">
                    <input class="form-control form-control-sm d-inline-block"
                           type="number" step="1"
                           v-model="form.unit_amount" disabled
                    >
                  </td>
                  <td class="navy-blue-border-right-1px">
                   {{calculated_vat}} {{ selectedCurrency }}
                  </td>
                  <td class="navy-blue-border-right-1px">
                   {{ total}} {{ selectedCurrency }}
                  </td>

                  <td class="navy-blue-border-right-1px">
                    {{ total_amount}} {{ selectedCurrency }}
                  </td>
                  <td class="navy-blue-border-right-1px">
                    <input class="form-control form-control-sm" type="text"
                           step="1" v-model="form.description" disabled
                    >
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="col-6 py-2" v-for="(item, index) in form.description">
              <div class="col-4">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.general_description') }} {{ ++index }} : </p>
              </div>
              <div class="col-8">
                <p class="font-size-lg">{{ item }}</p>
              </div>
            </div>

            <div class=" float-right w-50 lg:w-35 md:w-50 sm:w-50 py-3 px-5 mt-12 border-15px navy-blue-border-1px bg-white">
              <div class="w-100 row mx-0 px-0 font-weight-bold">
                <div class="col-12 py-1 pr-2 d-flex justify-content-between navy-blue-disabled-border-bottom-1px">
                  <div class="detail-width">{{ $t('invoice.material_service_total_amount')}}</div>
                  <div class="w-100px">{{total}}</div>
                </div>
                <div class="col-12 py-1 pr-2 d-flex justify-content-between navy-blue-disabled-border-bottom-1px">
                  <div class="detail-width">{{ $t('invoice.total_discount')}}</div>
                  <div class="w-150px">
                    <input class="form-control w-100px form-control-sm d-inline-block" type="text"
                                               step="1" v-model="form.total_discount" disabled
                  >  {{ selectedCurrency }} </div>
                </div>
                <div class="col-12 py-1 pr-2 d-flex justify-content-between navy-blue-disabled-border-bottom-1px">
                  <div class="detail-width">{{ $t('invoice.calculate_vat')}} (%{{options.vatOptions[form.vat_id]}} )</div>
                  <div class="w-100px">{{calculated_vat}}  {{ selectedCurrency }}</div>
                </div>
                <div class="col-12 py-1 pr-2 d-flex justify-content-between navy-blue-disabled-border-bottom-1px" >
                  <div class="detail-width">
                    {{ $t('invoice.withholding')}}
                    <span class="font-weight-normal" >({{ $t('invoice.withholding_extra_text')}})</span>
                  </div>
                  <div class="w-100px">

                    <input class="form-control w-90 form-control-sm d-inline-block" type="text"
                           step="1" v-model="form.withholding_amount" disabled
                    >
                  </div>
                </div>
                <div class="col-12 py-1 pr-2 d-flex justify-content-between navy-blue-disabled-border-bottom-1px">
                  <div class="detail-width">{{ $t('invoice.total_with_tax')}}</div>
                  <div class="w-100px">{{extraTotal}}  {{ selectedCurrency }}</div>
                </div>
                <div class="col-12 py-1 pr-2 d-flex justify-content-between">
                  <div class="detail-width">{{ $t('invoice.amount_to_be_paid')}}</div>
                  <div class="w-100px">{{extraTotal}} {{ selectedCurrency }}</div>
                </div>
              </div>
            </div>

            <div class="col-12 d-flex justify-content-center my-3 mt-10 pt-5 " >
              <button-with-icon
                  @onClick="submitInvoice"
                  :icon-name="null"
                  :text="$t('general.cancel')"
                  size="lg"
                  class="w-200px text-primary"
              ></button-with-icon>
            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import TextInput from "@/assets/components/inputs/TextInput";
import {mapMutations, mapGetters} from 'vuex'
import moment from "moment";


import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import NumberInput from "@/assets/components/inputs/NumberInput";
import FileInput from "@/assets/components/inputs/FileInput";
import DataTable from "@/assets/components/dataTable/DataTable";
import InfoTooltip from "@/assets/components/widget/InfoTooltip";

import {
  CREATE_ITEM,
  GET_ITEM_DETAIL_BY_ID,
  GET_ITEMS,
  HANDLE_INFINITE_SCROLL,
  LOADING,
  RESET_VALUES
} from "@/core/services/store/REST.module";
import {LARAVEL_DATE_FORMAT, LARAVEL_DATE_TIME_FORMAT, MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";
import SelectInput from "@/assets/components/inputs/SelectInput";
import InvoiceLayout from "@/view/pages/invoice/layouts/InvoiceLayout";

export default {
  name: "InvoiceCreateEdit",
  components: {
    InvoiceLayout,
    SelectInput,
    FileInput,
    NumberInput,
    ButtonWithIcon,
    DatePickerInput,
    CustomMultiSelect,
    DashboardBox,
    DataTable,
    InfoTooltip,
    TextInput
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
    }),
    selectedCurrency () {
      if (this.form.currency_unit_id) {
        return this.options.currencyOptions[this.form.currency_unit_id]
      }
      return "";
    },
    showVatExceptionField() {
      if (this.form.vat_id) {
        let data = this.options.vatOptions[this.form.vat_id];
        if (data == 0) {
          return true;
        }
      }
      return false;
    },
    total(){
      let sum ;
      sum = Number(this.form.amount)  * Number(this.form.unit_amount) ;
      return sum;
    },

    calculated_vat(){
      let sum;
      return  sum  = this.total * this.options.vatOptions[this.form.vat_id] / 100;
    },
    total_amount(){
      let sum;
      return  sum  = this.total + this.calculated_vat;
    },
    extraTotal(){
      let sum;
      return sum = parseInt(Number(this.total_amount) )  + parseInt(Number( this.form.withholding_amount) ) - parseInt(Number(this.form.total_discount) ) ;
    },


    isValidToSubmit() {
      if (!this.isValidToShowPopUp) return false;
      if (Number(this.form.exchange_rate) === 0) return false;
      if (Number(this.form.invoice_receiving_type_id) === 0) return false;
      if (Number(this.form.currency_unit_id) === 0) return false;
      return true;
    },

    isValidToSave() {
      if (Number(this.form.waybillable_id) === 0) return false;
      if (Number(this.form.document_no) === 0) return false;
      if (Number(this.form.supplier_company_id) === 0) return false;
      if (Number(this.form.invoice_type_id) === 0) return false;
      if (Number(this.form.invoice_kind_id) === 0) return false;
      if (Number(this.form.invoice_category_id) === 0) return false;
      if (this.form.invoice_no === null || this.form.invoice_no === '') return false;
      if (this.form.image === null) return false;
      return true;
    },

  },
  data() {
    return {
      id: null,
      route_type: null,
      unitOptions: [],
      vatDeducts:[],
      vatExceptions:[],
      is_submitting: false,
      today: moment(),
      currentHoveredItem: null,
      options: {
        vatOptions: [],
        unitOptions:[],
        currencyOptions:[],
        vatDeducts:[],
        vatExceptions:[],
      },
      form: {
        vat_deduct_id: "",
        vat_deduct_description: "",
        vat_exception_description: "",
        vat_exception_id: "",
        waybill_date: null,
        is_withholding: '0',
        expense_type_id: null,
        code: null,
        code_name: null,
        document_no: null,
        file_names: ['image'],
        supplier_company_id: null,
        invoice_type_id: null,
        invoice_category_id: null,
        invoice_kind_id: null,
        invoice_no: null,
        currency_id: null,
        total_discount: null,
        withholding_amount: null,
        withholding_code: null,
        vat_id: 1,
        image: null,
        invoice_date: moment().format(LARAVEL_DATE_FORMAT),
        waybill_ids: [],
        currency_unit_id: '0',
        invoice_receiving_type_id: '0',
        exchange_rate: null,
        vat:null,
        vat_amount:null,
        amount:null,
        select_unit_id:null,
        unit_amount:null,
        kdv_sum:null,
        sum:null,
        unit_id:null,
        total_sum:null,
        description:null,
      },
      items: {data: []},
    };
  },
  methods: {
    ...mapMutations({}),
    openPopUpModal() {
      this.$modal.show('invoice-confirm-modal');
      this.didClickCancel = false;
    },
    submitInvoice() {
      let self = this;

      if (!this.isUserGranted('Invoice', ['update'])) {
        return false;
      }

      this.$store.dispatch(CREATE_ITEM, {
        url: `api/change-invoice-status/` + this.$route.params.id + '/cancel',
        showLoading: true,
      }).then(response => {
        if (response.status) {
          setTimeout(() => {
            self.sweetAlertSuccess('invoice.invoice_success_message').then(() => {
              self.$router.push({name: 'invoice.outgoing'});
            });
          }, 500);
        } else {
          self.sweetAlertError(self.$t('general.internal_error'));
        }
      })
    },
    onInvoiceUpload(payload) {
      document.getElementById('invoice_image').src = payload.blob;
    },
  },
  created() {
    this.$store.commit(RESET_VALUES);
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t('general.dashboard'), route: "dashboard"},
      {title: this.$t('invoice.invoices'), route: "invoice.index"},
      {title: this.$t('invoice.edit')}
    ]);

    if (!this.isUserGranted('Invoice', ['view'])) {
      return false;
    }

    this.route_type =  this.$route.params.type;
    let self = this, promises = [];
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/invoices/get-invoice-relation-options`}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/supplier-companies`, filters: {all: true}}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/currency`}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/invoice-type`}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/invoice-category`}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/invoice-receive-type`}));
    promises.push(this.$store.dispatch(GET_ITEMS, {url: `api/expenses`}));
    promises.push(this.$store.dispatch(GET_ITEMS, { url: 'api/units', }));
    promises.push(this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {url: `api/invoice/` + this.$route.params.id}));
    promises.push(this.$store.dispatch(GET_ITEMS, { url: 'api/vat-rates', filters: {
        type_id: 1,
      } }));
    promises.push(this.$store.dispatch(GET_ITEMS, { url: 'api/vat-deducts', filters: {
        all: true
      } }));

    promises.push(this.$store.dispatch(GET_ITEMS, { url: 'api/vat-exceptions', filters: {
        all: true
      }}));
    Promise.all(promises).then(responses => {
      if (responses[0].status) {
        self.options.waybillableOptions = self.convertArrayToObjectByKey(responses[0].data, 'id', 'waybillable.name')
      }
      if (responses[1].status) {
        self.options.companyOptions = self.convertArrayToObjectByKey(responses[1].data, 'id', 'name_for_accounting');
      }
      if (responses[2].status) {
        self.options.currencyOptions = self.convertArrayToObjectByKey(responses[2].data, 'id', 'code');
      }
      if (responses[3].status) {
        self.options.invoiceTypeOptions = self.convertArrayToObjectByKey(responses[3].data.data, 'id', 'translations.0.name');
      }
      if (responses[4].status) {
        self.options.invoiceKindOptions = self.convertArrayToObjectByKey(responses[4].data.data, 'id', 'translations.0.name');
      }
      if (responses[5].status) {
        self.options.receiveTypeOptions = self.convertArrayToObjectByKey(responses[5].data.data, 'id', 'translations.0.name');
        self.options.receiveTypeOptions['0'] = self.$t('invoice.receive_type');
      }
      if(responses[6].status) {
        self.options.expenseTypeOptions = self.convertArrayToObjectByKey(responses[6].data, 'id', 'translations.0.name')
      }
      if (responses[7].status) {
        self.options.unitOptions = self.convertArrayToObjectByKey(responses[7].data.data, 'id', 'translations.0.name');
      }
      if (responses[9].status) {
        self.options.vatOptions = self.convertArrayToObjectByKey(responses[9].data, 'id', 'vat.rate');
        self.options.vatOptions['0'] = 0;
      }
      if (responses[10].status) {
        self.vatDeducts = responses[10].data;
        self.options.vatDeducts = self.convertArrayToObjectByKey(responses[10].data, 'id', 'code');
      }
      if (responses[11].status) {
        self.vatExceptions = responses[11].data;
        self.options.vatExceptions = self.convertArrayToObjectByKey(responses[11].data, 'id', 'code');
      }
      if (this.$route.params.id != null) {
        if (responses[8].status) {
          self.id = responses[8].data.id;
          self.today = moment(responses[8].data.created_at, LARAVEL_DATE_FORMAT);
          self.form.invoice_date = moment(responses[8].data.invoice_date, LARAVEL_DATE_FORMAT);
          self.form.waybill_date = moment(responses[8].data.created_at, LARAVEL_DATE_FORMAT);
          self.form.image = responses[8].data.image_link;
          self.form.waybillable_id = responses[8].data.waybillable_id;
          self.form.supplier_company_id = responses[8].data.supplier_company_id;
          self.form.invoice_no = responses[8].data.invoice_no;
          self.form.invoice_type_id = responses[8].data.invoice_type_id;
          self.form.vat_id = responses[8].data.vat_id;
          self.form.invoice_kind_id = responses[8].data.invoice_kind_id;
          self.form.invoice_category_id = responses[8].data.invoice_category_id;
          self.form.code = responses[8].data.code;
          self.form.code_name = responses[8].data.code_name;
          self.form.amount = responses[8].data.amount;
          self.form.total_discount = responses[8].data.total_discount;
          self.form.withholding_amount = responses[8].data.withholding_amount;
          self.form.currency_unit_id = responses[8].data.currency_unit_id;
          self.form.document_no = responses[8].data.document_no;
          self.form.unit_amount = responses[8].data.unit_amount;
          self.form.select_unit_id = responses[8].data.unit_id;
          self.form.withholding_code = responses[8].data.withholding_code;
          self.form.vat_deduct_id = responses[8].data.vat_deduct_id;
          self.form.vat_deduct_description = responses[8].data.vat_deduct_description;
          self.form.vat_exception_description = responses[8].data.vat_exception_description;
          self.form.vat_exception_id = responses[8].data.vat_exception_id;
          self.form.description = JSON.parse(responses[8].data.description);
        }
      }
    })
    setTimeout(this.onHitBottom, 500);
  },
  watch:{
    'form.vat_id'(newValue){
      if (newValue == '0'){
        this.form.is_withholding = 1;
      }
    },
    'form.vat_deduct_id'(value){
      let item = this.vatDeducts.find(item => item.id == value);
      this.form.vat_deduct_description = item.name;
      this.form.is_withholding = 1;
    },
    'form.vat_exception_id'(value){
      let item = this.vatExceptions.find(item => item.id == value);
      this.form.vat_exception_description = item.name;
    },
  }
}
</script>

<style lang="scss" scoped>
.h-50vh {
  height: 50vh !important;
}
</style>
